<template>
  <div>
    <div
      v-if="imageUrl && isVideo && !editImage"
      class="fill-height"
      style="position: relative"
    >
      <video
        style="max-width: 100%"
        class="mx-2"
        playsinline
        autoplay
        :muted="videoMuted"
        loop
      >
        <source :src="imageUrl" type="video/mp4" />
      </video>
      <v-btn
        v-if="allowEdit"
        fab
        absolute
        top
        rounded
        right
        class="mt-2"
        style="z-index: 1; position: absolute; right: 0; top: 0"
        @click="editImage = true"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <!-- add an unmuting button if the video is muted -->
      <v-btn
        text
        absolute
        bottom
        right
        rounded
        class="mb-10 white--text"
        dark
        style="z-index: 1; position: absolute; right: 0; bottom: 0"
        @click="videoMuted = !videoMuted"
      >
        <v-icon v-if="!videoMuted">mdi-volume-high</v-icon>
        <v-icon v-else>mdi-volume-off</v-icon>
      </v-btn>
    </div>
    <v-row
      v-else-if="allowEdit || editImage"
      v-show="!imageUrl || editImage"
      justify="start"
      style="border: 2px solid var(--v-light-background)"
      class="mb-3 mx-1"
    >
      <v-col cols="12">
        <v-container fill-height justify-center>
          <div style="text-align: center">
            <h1>Drop here</h1>
            <br />
            <h3>or</h3>
            <vue-upload-component
              :ref="`upload-${name}`"
              :key="`upload-${name}`"
              v-model="image"
              :input-id="`upload-${name}`"
              :drop="true"
              :drop-directory="true"
              @input-file="handleImage()"
            >
              <v-btn class="mt-4 pa-5" color="primary">
                <v-icon class="mb-1">mdi-plus</v-icon>
                Choose Image / Video
              </v-btn>
            </vue-upload-component>
            <p class="mt-4">
              Current {{ isVideo ? "video" : "image" }}:
              <video
                v-if="imageUrl && isVideo"
                style="max-width: 100%; max-height: 200px"
                class="mb-n2"
                playsinline
                autoplay
                muted
                loop
              >
                <source :src="imageUrl" type="video/mp4" />
              </video>
              <v-img v-else-if="imageUrl" :src="imageUrl" height="60px" contain />
              <span v-if="!imageUrl">
                {{ image.length ? image[0].name : imageUrl ? imageUrl : "N/A" }}
              </span>
            </p>
            <v-btn v-if="imageUrl && editImage" text @click="editImage = false">
              Cancel
            </v-btn>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row
      v-if="imageUrl && !isVideo && !editImage"
      justify="center"
      class="my-0 mb-3 mx-1"
    >
      <v-col
        cols="auto"
        class="pa-0"
        style="border: 2px solid var(--v-light-background)"
      >
        <v-container justify-center class="pa-0">
          <v-img
            :src="imageUrl"
            contain
            max-height="400"
            max-width="600"
            min-height="200"
            min-width="200"
          >
            <div v-if="allowEdit" v-show="imageUrl">
              <v-btn
                fab
                absolute
                top
                rounded
                right
                class="mt-8"
                @click="editImage = true"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </v-img>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-else-if="!allowEdit">
      <v-col>
        <p class="text-center">No image available.</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { uploadBytes, ref, getDownloadURL, getMetadata } from "@firebase/storage"
import VueUploadComponent from "vue-upload-component"
import { storage } from "../../firebaseConfig"
import { mapState } from "vuex"

export default {
  name: "custom-image-upload",
  components: { VueUploadComponent },
  props: {
    name: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: [String, undefined],
      required: true,
    },
    allowEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    location: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      preppedImage: "",
      image: [],
      editImage: false,
      videoMuted: true,
      metadata: null,
    }
  },
  computed: {
    ...mapState(["firebaseRefs"]),
    isVideo() {
      return this.metadata?.contentType?.includes("video")
    },
    imageRefFromUrl() {
      if (!this.imageUrl) return null
      const imagePath = decodeURIComponent(
        this.imageUrl
          ?.split(storage?._location?.bucket + "/o/")?.[1]
          ?.split("?")?.[0]
      )
      if (imagePath && imagePath !== "undefined") {
        return ref(storage, imagePath)
      } else return null
    },
  },
  watch: {
    imageUrl() {
      this.getImageMetadata()
    },
  },
  mounted() {
    this.getImageMetadata()
  },
  methods: {
    getImageMetadata() {
      if (this.imageRefFromUrl) {
        getMetadata(this.imageRefFromUrl)
          .then(metadata => {
            this.metadata = metadata
          })
          .catch(error => {
            console.error("Error retrieving image metadata:", error)
          })
      }
    },
    async handleImage() {
      if (this.image.length) {
        // create a name for this file using the current date, time and also a 6-digit random string
        // this is to ensure that the file name is unique
        const fileName =
          new Date().toISOString().replace(/:/g, "-") +
          "-" +
          Math.random().toString(36).substring(2, 8)
        const imagePath = this.location
          ? `Organizations-v1/${this.firebaseRefs.organization.id}/${this.location}`
          : `restaurantManagerUploads/${this.name}/${fileName}`
        const imageRef = ref(storage, imagePath)
        await uploadBytes(imageRef, this.image[0].file)
        const imageURL = await getDownloadURL(imageRef)
        this.preppedImage = imageURL
        this.$emit("imageProcessed", this.preppedImage)
      }
      this.image = []
      this.editImage = false
      this.preppedImage = ""
    },
  },
}
</script>

<style></style>
